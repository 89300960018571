<template>
	<v-container fluid class="car-list-container py-5 px-8">
		<car-list-title />

		<v-row class="d-flex flex-wrap justify-start" v-if="isReady">
			<transition name="fade">
				<grid-display v-if="isGridDisplay" :cars="listingCars" :has-thumb-img-zoom="hasThumbImgZoom" />
			</transition>
			<transition name="fade">
				<list-display v-if="!isGridDisplay" :cars="listingCars" :has-thumb-img-zoom="hasThumbImgZoom" />
			</transition>
		</v-row>

		<div class="text-center mt-10">
			{{ listingTotalCount }} sonuç arasından {{ listingFilteredCount }} kayıt gösteriliyor.
		</div>
		<div class="d-flex justify-center my-5">
			<v-btn outlined light x-large @click="loadMore" :loading="isFetching" :disabled="!hasMoreData">
				{{ moreText }}
			</v-btn>
		</div>
	</v-container>
</template>

<script>
import { GET_MORE_CARS, SET_DISPLAY_TYPE } from '@/store/modules/listing.module';
import { mapActions, mapGetters } from 'vuex';
import carListTitle from './carListTitle.vue';
import GridDisplay from './gridDisplay.vue';
import ListDisplay from './listDisplay.vue';

export default {
	components: { carListTitle, GridDisplay, ListDisplay },
	name: 'carList',
	data() {
		return {};
	},
	computed: {
		...mapGetters([
			'displayType',
			'listingCars',
			'isFetching',
			'hasMoreData',
			'listingFilteredCount',
			'listingTotalCount',
			'hasThumbImgZoom',
		]),
		isReady() {
			return this.listingCars.length > 0;
		},
		isGridDisplay() {
			return this.displayType === 0;
		},
		moreText() {
			return this.hasMoreData ? 'Daha fazla...' : 'Başka bir şey bulamadık...';
		},
	},
	methods: {
		...mapActions({
			setDisplayType: SET_DISPLAY_TYPE,
			getMore: GET_MORE_CARS,
		}),
		loadMore() {
			this.getMore();
		},
	},
	watch: {
		'$vuetify.breakpoint': {
			handler(value) {
				if (value.xsOnly) this.setDisplayType(0);
			},
			deep: true,
		},
	},
};
</script>

<style lang="scss">
.heart {
	width: 100px;
	height: 100px;
	background: url('https://cssanimation.rocks/images/posts/steps/heart.png') no-repeat;
	background-position: 0 0;
	cursor: pointer;
	transition: background-position 1s steps(28);
	transition-duration: 0s;
	transform: scale(0.6);

	&.is-active {
		transition-duration: 1s;
		background-position: -2800px 0;
	}
}

.fade-enter-active,
.fade-leave-active {
	transition-property: opacity;
	transition-duration: 0s;
}

.fade-enter-active {
	transition-delay: 0.3s;
	transition-duration: 0.3s;
}

.fade-enter,
.fade-leave-active {
	opacity: 0;
}

.car-list {
	.list-display {
		box-shadow: 0 4px 8px rgb(0 78 128 / 8%), 0 12px 24px rgb(0 0 0 / 12%) !important;
		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}

	.grid-display {
		.car-card {
			box-shadow: 0 4px 8px rgb(0 78 128 / 8%), 0 12px 24px rgb(0 0 0 / 12%) !important;
		}
	}

	.direction-icon {
		background: rgba(0, 0, 0, 0.01) !important;
	}

	.feature-text {
		color: #333 !important;
		font-weight: 600 !important;
		font-size: 1rem !important;
		&--mobile {
			font-size: 0.9rem !important;
		}
	}

	.car-plate {
		font-size: 1rem !important;
		display: inline-block;
	}

	.feature-icon {
		margin-bottom: 10px;
	}

	.zoom-img--hover {
		background-position: center;
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.5s ease;
		&:hover {
			-webkit-transform: scale(1.2);
			-moz-transform: scale(1.2);
			-o-transform: scale(1.2);
			-ms-transform: scale(1.2);
			transform: scale(1.2);
			filter: blur(1.2px);
			-webkit-filter: blur(1.2px);
		}
	}

	.zoom-img {
		.img-gradient {
			background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%, transparent 100px);
		}
	}
}
</style>
