<template>
	<v-dialog v-model="dialog" fullscreen scrollable hide-overlay transition="dialog-bottom-transition">
		<template v-slot:activator="{ on, attrs }">
			<v-btn light depressed color="grey lighten-3" class="text-capitalize font-weight-bold" v-bind="attrs" v-on="on">
				<v-icon color="template-color" x-small left>fas fa-filter</v-icon>
				filtre
			</v-btn>
		</template>
		<v-card tile>
			<v-toolbar light>
				<v-btn icon @click="dialog = false">
					<v-icon>fas fa-times</v-icon>
				</v-btn>
				<v-toolbar-title class="px-0">Filtreler</v-toolbar-title>
			</v-toolbar>
			<v-card-text class="pa-0">
				<filter-side />
			</v-card-text>
			<v-card-actions class="py-5 grey lighten-5">
				<v-btn
					x-large
					light
					block
					color="template-color"
					class="template-text--text text-lowercase font-weight-bold"
					:loading="fetching"
					:disabled="applyButtonDisabled"
					@click="fetchCars"
				>
					{{ applyButtonText }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { FETCH_TEMP_CAR_LIST, SET_REQUEST_READY_STATUS } from '@/store/modules/listing.module';
import { mapActions, mapGetters } from 'vuex';
import filterSide from './filterSide.vue';

export default {
	components: { filterSide },
	name: 'MobileFilter',
	data() {
		return {
			dialog: false,
			fetching: false,
		};
	},
	computed: {
		...mapGetters(['readyForRequest', 'forMobileTmpTotalCount']),
		applyButtonText() {
			return this.forMobileTmpTotalCount > 0 ? `${this.forMobileTmpTotalCount} aracı listele` : 'Araç bulunamadı';
		},
		applyButtonDisabled() {
			return this.forMobileTmpTotalCount === 0;
		},
	},
	methods: {
		...mapActions({
			fetchTempCars: FETCH_TEMP_CAR_LIST,
			setRequestReadyStatus: SET_REQUEST_READY_STATUS,
		}),
		fetchCars() {
			this.fetching = true;

			this.fetchTempCars().finally(() => {
				setTimeout(() => {
					this.fetching = false;
					this.dialog = false;
				}, 1000);
			});
		},
	},
};
</script>

<style></style>
