<template>
	<div class="container">
		<div class="histogram-slider d-flex flex-column" :style="style" v-if="componentIsReady">
			<div class="series d-flex justify-space-between">
				<div
					ref="serie"
					v-for="(serie, index) in sortedSeries"
					:key="`serie-${index}`"
					class="serie-item flex-grow-1"
					:class="{
						'grey lighten-2':
							serie.label < seriesLabel[normalizedrangeStep[0]] || serie.label > seriesLabel[normalizedrangeStep[1]],
					}"
					:style="calcHeight(index)"
				></div>
			</div>
			<v-range-slider
				:color="'template-color'"
				ref="histogramSlider"
				:value="seriesLabel"
				:min="0"
				:max="maxSliderStep - 1"
				:height="0"
				hide-details="auto"
				hide-spin-buttons
				@change="sliderOnChange"
				@input="sliderOnUpdate"
				v-model="rangeStep"
			>
			</v-range-slider>
			<div class="d-flex flex-row justify-space-between mt-3">
				<div>
					<v-text-field
						hide-details
						outlined
						dense
						class="rounded-r-0 right-text-input start"
						:color="'template-color'"
						:placeholder="startPlaceHolder"
						@keypress="textOnKeypress"
						@input="inputCaptured"
						v-model.number="start"
					/>
				</div>
				<div>
					<v-text-field
						hide-details
						outlined
						dense
						class="rounded-l-0 right-text-input end"
						:color="'template-color'"
						:placeholder="endPlaceHolder"
						@keypress="textOnKeypress"
						@input="inputCaptured"
						v-model.number="end"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/* eslint-disable no-restricted-globals */

export default {
	name: 'HistogramSlider',
	props: {
		series: {
			type: Array,
			required: true,
		},
		step: {
			type: Number,
			default: 1,
		},
		height: {
			type: Number,
			default: 100,
		},
		barContainerHeight: {
			type: Number,
			default: 50,
		},
	},
	created() {
		this.$nextTick(() => {
			this.init();
		});
	},
	data() {
		return {
			rangeStep: null,
			rangeValue: [0, 0],
			start: null,
			end: null,
		};
	},
	computed: {
		componentIsReady() {
			return this.sortedSeries?.length > 0 && this.rangeStep !== null;
		},
		sortedSeries() {
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			return this.series?.sort((a, b) => a.label - b.label);
		},
		seriesCount() {
			return this.sortedSeries.length;
		},
		maxSliderStep() {
			return this.seriesCount;
		},
		maxCount() {
			return Math.max(...this.sortedSeries.map((x) => x.count));
		},
		seriesLabel() {
			return this.sortedSeries.map((s) => s.label);
		},
		style() {
			return {
				'--v-slider-size': `${this.$vuetify.breakpoint.mobile ? 18 : 14}px`,
				'--v-height': `${this.normalizedHeight}`,
				'--v-bar-container-height': this.normalizedBarContainerHeight,
				'--v-tick-size': '5px',
			};
		},
		normalizedHeight() {
			return `${this.height}px`;
		},
		normalizedBarContainerHeight() {
			return `${this.barContainerHeight}px`;
		},
		normalizedrangeStep() {
			return this.rangeStep;
		},
		computedRangeValue() {
			const minValue = this.seriesLabel[this.rangeStep[0]];
			const maxValue = this.seriesLabel[this.rangeStep[1]];

			return [minValue, maxValue];
		},
		startPlaceHolder() {
			return this.rangeValue[0]?.toString();
		},
		endPlaceHolder() {
			return this.rangeValue[1]?.toString();
		},
		inputCaptured() {
			return this.$debounce(this.textOnBlur, 1000, { trailing: true });
		},
	},
	methods: {
		init() {
			this.rangeStep = [0, this.maxSliderStep];
			this.$nextTick(() => {
				this.$emit('input', this.computedRangeValue);
			});
		},
		reset() {
			this.init();
		},
		textOnKeypress(event) {
			// numeric only
			if (event.keyCode < 48 || event.keyCode > 57) {
				event.preventDefault();
			}
		},
		textOnBlur() {
			let start = parseInt(this.start, 10);
			let end = parseInt(this.end, 10);
			start = isNaN(start) ? parseInt(this.startPlaceHolder, 10) : start;
			end = isNaN(end) ? parseInt(this.endPlaceHolder, 10) : end;
			this.$emit('input', [start, end]);
		},
		sliderOnUpdate() {
			if (this.start !== null || this.end !== null) {
				this.start = null;
				this.end = null;
			}
			this.rangeValue = this.computedRangeValue;
			if (this.rangeStep[0] > this.rangeStep[1] && this.rangeStep[1] < this.rangeStep[0]) {
				this.rangeStep = [this.rangeStep[0], this.rangeStep[1]];
			}
		},
		sliderOnChange() {
			this.$emit('input', this.computedRangeValue);
		},
		calcHeight(index) {
			const serie = this.sortedSeries[index];
			const scale = serie ? (100 * serie.count) / this.maxCount / 100 : 0;
			return {
				transform: `ScaleY(${scale})`,
			};
		},
		addSerie(serie) {
			if (this.sortedSeries.some((s) => s.label === serie.label)) {
				return;
			}
			this.series.push(serie);
		},
	},
};
</script>

<style lang="scss">
.histogram-slider {
	.right-text-input input {
		text-align: right;
	}

	.v-input {
		&.start {
			fieldset {
				border-right: none !important;
			}
		}
	}

	.v-slider {
		&__thumb {
			width: var(--v-slider-size);
			height: var(--v-slider-size);
			background-color: var(--v-template-color-darken1) !important;
		}
		&__thumb-container {
			&--active {
				.v-slider__thumb {
					&:before {
						transform: scale(0) !important;
					}
				}
			}
			&--focused {
				.v-slider__thumb {
					&:before {
						transform: scale(0) !important;
					}
				}
			}
			&:hover {
				.v-slider__thumb {
					&:before {
						transform: scale(0) !important;
					}
				}
			}
		}
		&__track-container {
			.v-slider__track-background {
				background-color: var(--v-template-color-lighten2) !important;
			}
		}
	}

	.series {
		height: var(--v-bar-container-height);

		.serie-item {
			min-height: 100%;
			transition-property: transform;
			transition-duration: 1s;
			transform-origin: center bottom;
			background-color: var(--v-template-color-darken1);
			margin: 0px 1px;
		}
	}

	.v-slider {
		&--horizontal {
			margin: 0px !important;
			min-height: auto !important;

			.v-slider__track-container {
				top: 100%;
				transform: unset;
			}
		}
	}
}
</style>
