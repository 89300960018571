<template>
	<v-col>
		<v-card
			v-for="(car, index) in mappedCars"
			light
			flat
			class="list-display"
			:key="`car-${index}`"
			:to="{ name: 'carDetails', params: createURLParams(car) }"
		>
			<v-card-text>
				<v-row>
					<v-col cols="12" xl="2" lg="3" md="5" sm="5">
						<div class="advantage-car advantage-car--list" v-if="car.hasAdvantage || car.isReserved">
							<div v-if="car.hasAdvantaged">
								<v-chip small color="template-color" text-color="template-text">
									<v-icon x-small left small> fas fa-star</v-icon>
									Fırsat aracı
								</v-chip>
							</div>
							<div v-if="car.isReserved">
								<v-chip small color="template-color darken-3" text-color="template-text">
									<v-icon x-small left small> fas fa-check</v-icon>
									Opsiyonlu
								</v-chip>
							</div>
						</div>
						<v-carousel
							class="rounded-lg"
							hide-delimiters
							hide-delimiter-background
							show-arrows-on-hover
							height="auto"
							:show-arrows="car.photos.length > 1"
						>
							<template v-slot:prev="{ on, attrs }">
								<v-btn
									class="direction-icon"
									depressed
									width="28"
									height="28"
									fab
									v-bind="attrs"
									v-on="on"
									@click.prevent
								>
									<v-icon centered x-small> fas fa-chevron-left</v-icon>
								</v-btn>
							</template>
							<template v-slot:next="{ on, attrs }">
								<v-btn
									class="direction-icon"
									depressed
									width="28"
									height="28"
									fab
									v-bind="attrs"
									v-on="on"
									@click.prevent
								>
									<v-icon centered x-small> fas fa-chevron-right</v-icon>
								</v-btn>
							</template>
							<v-carousel-item v-for="(photo, pindex) in car.photos" :key="`car-photo-${pindex}`">
								<template v-slot:default>
									<div class="favorite">
										<v-btn icon width="36" height="36" @click.prevent="addToFavorites(car.id)">
											<v-icon small :color="isFavorite(car.id) ? 'red' : 'grey'">fa-heart</v-icon>
										</v-btn>
									</div>
									<v-img
										class="zoom-img"
										:class="{ 'zoom-img--hover': hasThumbImgZoom }"
										contain
										:aspect-ratio="4 / 3"
										height="100%"
										max-height="100%"
										:src="`${photo}`"
										:lazy-src="`${photo}`"
									>
									</v-img>
								</template>
							</v-carousel-item>
						</v-carousel>
					</v-col>
					<v-col cols="12" xl="10" lg="9" md="7" sm="7">
						<div class="d-flex fill-height flex-wrap justify-space-between">
							<div class="d-flex flex-column justify-space-start flex-wrap pr-lg-10">
								<v-card-title class="font-weight-bold pa-0 mb-5 black--text" :title="title(car)">
									{{ title(car) }}
								</v-card-title>
								<v-card-subtitle class="pa-0 font-weight-bold car-plate">
									{{ car.plate }}
								</v-card-subtitle>
								<div class="d-flex flex-row justify-space-between">
									<template v-for="(feature, featureIndex) in car.features">
										<div
											class="d-flex flex-column mt-5 feature-text"
											:class="{
												'mr-lg-10 mr-sm-6 mx-1': car.features.length > 0,
												'feature-text--mobile': $vuetify.breakpoint.smAndDown,
											}"
											:key="`feature-${featureIndex}`"
											v-if="feature.show"
										>
											<v-icon color="template-color darken-3" class="feature-icon">{{ feature.icon }}</v-icon>
											{{ feature.value }}
										</div>
									</template>
								</div>
							</div>
							<div class="font-weight-bold d-flex align-lg-start justify-end">
								<div class="font-weight-bold text-h5 text-md-h4 text-right justify-space-between align-center d-flex">
									<div
										v-if="showCredit && car.minCreditPerMonth > 0"
										class="d-flex align-center text-left font-weight-regular mr-5"
										style="font-size: 13px; line-height: 13px"
									>
										<span class="font-weight-bold mr-1">
											{{
												car.minCreditPerMonth | tl({ fractionCount: 0, avoidEmptyDecimals: '', symbolPosition: 'back' })
											}}
										</span>
										| Aylık
									</div>

									{{ car.salesPrice | tl({ avoidEmptyDecimals: '' }) }}
								</div>
							</div>
						</div>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-col>
</template>

<script>
import carFavoriteMixin from '../_mixins/carFavoriteMixin';
import carFeatureMixin from '../_mixins/carFeatureMixin';
import carListMixin from '../_mixins/carListMixin';

export default {
	name: 'ListDisplay',
	mixins: [carFavoriteMixin, carFeatureMixin, carListMixin],
	props: {
		cars: {
			type: Array,
			default: () => [],
			required: true,
		},
		hasThumbImgZoom: {
			type: Boolean,
			default: () => true,
			required: true,
		},
	},
	computed: {
		mappedCars() {
			// eslint-disable-next-line no-unused-vars
			return this.cars.map((car, index) => ({
				...car,
				features: this.mapFeatures(car),
				// photos: car.photos.map((photo, pindex) => `${photo}-${index}-${pindex}`),
			}));
		},
	},
};
</script>

<style lang="scss" scoped></style>
