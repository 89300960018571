<template>
	<v-row no-gutters class="d-flex justify-space-between" :class="[`flex-${flexDirection}`]">
		<div class="text-h5 d-flex justify-space-between align-center" :class="{ 'mb-2': flexDirection === 'column' }">
			<div class="d-flex justify-center align-center">
				<span class="text-h4 font-weight-bold mr-2">{{ listingTotalCount }}</span> <span>araç bulundu.</span>
			</div>

			<mobile-filter v-if="$vuetify.breakpoint.smAndDown" />
		</div>

		<div
			:class="{
				'container container--fluid pa-0': $vuetify.breakpoint.xsOnly,
				'actions d-flex align-center justify-end': $vuetify.breakpoint.smAndUp,
			}"
		>
			<v-btn-toggle
				v-if="$vuetify.breakpoint.mdAndUp"
				light
				borderless
				mandatory
				v-model="displayTypeComputed"
				active-class="template-color lighten-1"
				background-color="grey lighten-3"
				class="mr-2 display-type pa-1"
			>
				<v-btn>
					<v-icon small>fas fa-grip</v-icon>
				</v-btn>
				<v-btn>
					<v-icon small>fas fa-grip-lines</v-icon>
				</v-btn>
			</v-btn-toggle>
			<v-select
				:rounded="false"
				:items="sortFields"
				v-model="selectedSortFieldComputed"
				outlined
				color="template-color"
				item-color="template-color"
				hide-details="auto"
				item-text="label"
				item-value="id"
				single-line
				light
				:class="{ 'sort-field-select': $vuetify.breakpoint.mdAndUp }"
			>
				<template v-slot:selection="{ item }">
					<v-icon color="template-color" x-small left>fas fa-sort</v-icon>
					<div class="font-weight-bold">{{ item.label }}</div>
				</template>
				<template v-slot:item="{ item }">
					<v-list-item-title class="text-capitalize">
						{{ item.label }}
					</v-list-item-title>
				</template>
			</v-select>
		</div>
	</v-row>
</template>

<script>
import { listingDataSortType } from '@/common/enums';
import { SET_DISPLAY_TYPE, SORT_LIST } from '@/store/modules/listing.module';
import { mapActions, mapGetters } from 'vuex';
import mobileFilter from '../filter/mobileFilter.vue';

export default {
	components: { mobileFilter },
	name: 'CarListTitle',
	data() {
		return {};
	},
	computed: {
		...mapGetters(['displayType', 'selectedSortField', 'listingFilteredCount', 'listingTotalCount']),
		displayTypeComputed: {
			get() {
				return this.displayType;
			},
			set(newValue) {
				this.changeDisplayType(newValue);
			},
		},
		selectedSortFieldComputed: {
			get() {
				return this.selectedSortField;
			},
			set(fieldIndex) {
				this.sortList({ sortType: fieldIndex });
			},
		},
		sortFields() {
			return Object.values(listingDataSortType);
		},
		flexDirection() {
			const { name } = this.$vuetify.breakpoint;
			switch (name) {
				case 'xl':
					return 'row';
				case 'lg':
					return 'row';
				case 'md':
					return 'row';
				case 'sm':
					return 'column';
				default:
					return 'column';
			}
		},
	},
	methods: {
		...mapActions({
			changeDisplayType: SET_DISPLAY_TYPE,
			sortList: SORT_LIST,
		}),
	},
};
</script>

<style lang="scss" scoped>
.display-type {
	.v-btn {
		.v-icon {
			color: #51555a !important;
		}
		&--active {
			.v-icon {
				color: var(--v-template-text-base) !important;
			}
		}
	}
}
.sort-field-select {
	max-width: 270px;
	width: auto;
}
</style>
